import { Badge, Text, VStack } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Tag } from '@opengovsg/design-system-react'

import { GetTemplateDto } from '~shared/dtos/templates.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { TemplatePreviewThumbnail } from './thumbnail/TemplatePreviewThumbnail'

type TemplateCardProps = {
  template: GetTemplateDto
  index: number
  onClick: (index: number) => void
}

export const TemplateCard = ({
  template,
  index,
  onClick,
}: TemplateCardProps) => {
  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )

  return (
    <VStack spacing={3} align={'left'}>
      <TemplatePreviewThumbnail
        html={template.html}
        align={'center'}
        aspectRatio={'0.71'}
        borderRadius={'4px'}
        onClick={() => onClick(index)}
        _hover={{
          cursor: 'pointer',
          border: '2px solid',
          borderColor: 'interaction.main.default',
          shadow: 'md',
        }}
        bg="white"
        shadow={'sm'}
      />
      <Text noOfLines={2} textStyle={'subhead-1'}>
        {template.name}
      </Text>
      {template.id === sampleTemplateId && (
        <Badge variant="subtle" borderRadius={'xl'} width={'fit-content'}>
          Sample template
        </Badge>
      )}
    </VStack>
  )
}
