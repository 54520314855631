import { UseToastOptions } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useRef } from 'react'
import { WretchError } from 'wretch/types'

import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import { UploadImageResponseDto } from '~shared/dtos/templates.dto'

export const useUploadImage = () => {
  const toast = useToast()
  const toastIdRef = useRef<string | number>()

  const { mutateAsync } = useMutation(
    async (image: File): Promise<UploadImageResponseDto> => {
      toastIdRef.current = toast({
        title: 'Image is being uploaded, this may take a few seconds',
        status: 'loading',
        colorScheme: 'warning',
      })

      return await api
        .url('/templates/images')
        .formData({ image })
        .post()
        .json<UploadImageResponseDto>()
    },
    {
      onError: (err: WretchError) => {
        const errorToast: UseToastOptions = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          title: err.json?.message as string,
          status: 'error',
        }
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, errorToast)
        } else {
          toastIdRef.current = toast(errorToast)
        }
      },
      onSettled: (data) => {
        if (!data || !data.url) {
          const infoToast: UseToastOptions = {
            title: 'File uploads are not supported',
            status: 'info',
          }
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, infoToast)
          } else {
            toastIdRef.current = toast(infoToast)
          }
        }
      },
    },
  )

  return mutateAsync
}
