import { jsPDF } from 'jspdf'

import { ACCEPTED_EDITED_TEMPLATE_FIELDS_REGEX } from '~shared/constants/regex'
import { parseTemplateField } from '~shared/util/templates'

// Pixel ratios of width to height of A4 letters
export const WIDTH_A4 = 794
export const HEIGHT_A4 = 1122

// Highlight HTML consts
const CUSTOM_ERROR_HIGHLIGHT_HTML =
  '<mark style="background-color: rgba(255, 0, 0, 0.3)" class="custom-highlight">{{'
const CUSTOM_HIGHLIGHT_HTML =
  '<mark style="background-color: rgba(255, 240, 0, 0.5)">{{'

// Additional wrapper div to resize contents of letters
const resizeToA4Dimensions = (
  htmlString: string,
  width = WIDTH_A4,
  height = HEIGHT_A4,
): string => `<div style="width: ${width}px; height: ${height}px;"> 
  ${htmlString}</div>
`

// Convert HTML to PDF
export const convertHtmlToPdf = (
  htmlContent: string,
  pdfPath: string,
): Promise<void> => {
  // Create a new jsPDF instance
  const pdf = new jsPDF({ format: 'A4', compress: true })
  const htmlString = resizeToA4Dimensions(htmlContent)
  return new Promise((resolve) => {
    void pdf.html(htmlString, {
      callback: function () {
        // Save the PDF document
        pdf.save(pdfPath)
        resolve()
      },
      width: pdf.internal.pageSize.getWidth(), // A4 page width
      windowWidth: WIDTH_A4, // element width
    })
  })
}

// Detect when all images within a html element load, and trigger provided callback
export const onHtmlImagesLoaded = (
  node: HTMLDivElement,
  callback: () => void,
  maxTimeToWaitInMs = 1000,
) => {
  const images = node.getElementsByTagName('img')
  if (images.length === 0) {
    callback()
    return
  }

  let loadedImages = 0

  const checkAllLoaded = () => {
    loadedImages++
    if (loadedImages === images.length) {
      clearTimeout(timeoutId) // Clear the timeout if all images are loaded.
      callback()
    }
  }

  const timeoutId = setTimeout(callback, maxTimeToWaitInMs)

  for (const img of images) {
    if (img.complete) {
      checkAllLoaded()
    } else {
      img.addEventListener('load', checkAllLoaded)
    }
  }
}

export const highlightVariablesInHTMLTemplate = (htmlContent: string) => {
  let highlightedHTML = ''
  let seenOpenBrace = false
  for (let i = 0; i < htmlContent.length - 1; i++) {
    // track if we are inside angular braces '<'
    if (!seenOpenBrace && htmlContent[i] == '<') {
      seenOpenBrace = true
    }

    // close this once we exit the angular braces '>'
    // Note: Nesting of angular braces inside HTML is not allowed
    if (seenOpenBrace && htmlContent[i] == '>') {
      seenOpenBrace = false
    }

    // If we are not inside angular braces append special highlights
    if (htmlContent.substring(i, i + 2) == '{{' && !seenOpenBrace) {
      highlightedHTML += CUSTOM_HIGHLIGHT_HTML
      i++
    } else if (htmlContent.substring(i, i + 2) == '}}' && !seenOpenBrace) {
      highlightedHTML += '}}</mark>'
      i++
    } else {
      highlightedHTML += htmlContent[i]
    }
  }
  return highlightedHTML
}

export const highlightVariablesInHTMLTemplateEditor = (htmlContent: string) => {
  let highlightedHTML = ''
  let seenOpenBrace = false
  let seenOpenCurlyBraces = false
  for (let i = 0; i < htmlContent.length - 1; i++) {
    // track if we are inside angular braces '<'
    if (!seenOpenBrace && htmlContent[i] == '<') {
      seenOpenBrace = true
    }

    // close this once we exit the angular braces '>'
    // Note: Nesting of angular braces inside HTML is not allowed
    if (seenOpenBrace && htmlContent[i] == '>') {
      seenOpenBrace = false
    }

    // If we are not inside angular braces append special highlights
    if (htmlContent.substring(i, i + 2) == '{{' && !seenOpenBrace) {
      highlightedHTML += CUSTOM_ERROR_HIGHLIGHT_HTML
      seenOpenCurlyBraces = true
      i++
    } else if (
      htmlContent.substring(i, i + 2) == '}}' &&
      !seenOpenBrace &&
      seenOpenCurlyBraces
    ) {
      const lastIndexOf = highlightedHTML.lastIndexOf(
        CUSTOM_ERROR_HIGHLIGHT_HTML,
      )
      const substring = highlightedHTML.substring(lastIndexOf)
      const content = highlightedHTML.substring(
        lastIndexOf + CUSTOM_ERROR_HIGHLIGHT_HTML.length,
      )
      // format variables to the acceptable format
      const variableName = parseTemplateField(content)

      highlightedHTML = highlightedHTML.replace(
        substring,
        CUSTOM_HIGHLIGHT_HTML + variableName + '}}</mark>',
      )
      seenOpenCurlyBraces = false
      i++
    } else {
      highlightedHTML += htmlContent[i]
    }
  }
  return highlightedHTML
}

export const sanitizeTemplateVariablesBeforeSaving = (html: string): string => {
  return html.replaceAll(
    ACCEPTED_EDITED_TEMPLATE_FIELDS_REGEX,
    function (match, rest: string): string {
      return match ? '{{' + parseTemplateField(match.slice(2, -2)) + '}}' : rest
    },
  )
}
