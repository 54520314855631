import { Box, Text, VStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import { LetterViewer } from '~features/editor/components/LetterViewer'
import { calculateTransformScale } from '~features/public/hooks/useTransformScale'
import { LetterParamMap } from '~shared/dtos/letters.dto'
import { replaceWithParams } from '~shared/util/letters'
import {
  highlightVariablesInHTMLTemplate,
  onHtmlImagesLoaded,
} from '~utils/htmlUtils'

interface PreviewTemplateLibraryProps {
  templateHtml?: string | null
  letterScale?: number
  isPlaceholdersHighlighted?: boolean
  letterParamMap?: LetterParamMap
}
export const PreviewTemplateLibrary = ({
  templateHtml,
  letterScale,
  isPlaceholdersHighlighted = false,
  letterParamMap,
}: PreviewTemplateLibraryProps) => {
  const transformScale = calculateTransformScale(letterScale ?? 600) // TODO: should be able to avoid hardcoding by reading from parent drawer component
  const [letterHeight, setLetterHeight] = useState<number | undefined>(0)
  let letterHtml = templateHtml

  if (templateHtml && letterParamMap)
    letterHtml = replaceWithParams(templateHtml, letterParamMap)

  const [highlightedTemplateHtml, setHighlightedTemplateHtml] = useState<
    string | undefined
  >()
  const letterViewerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        // explicitly waits for images to load before setting letter height
        onHtmlImagesLoaded(node, () => setLetterHeight(node.offsetHeight))
      }
    },
    [setLetterHeight, letterHtml],
  )

  useEffect(() => {
    setHighlightedTemplateHtml(undefined)
    if (isPlaceholdersHighlighted && letterHtml) {
      const highlightedTemplateHtml =
        highlightVariablesInHTMLTemplate(letterHtml)

      setHighlightedTemplateHtml(highlightedTemplateHtml)
    }
  }, [letterHtml, isPlaceholdersHighlighted])

  return letterHtml ? (
    <Box height="90%" width="100%" overflow="auto">
      <VStack
        align={'center'}
        height={letterHeight ? letterHeight * transformScale + 50 : 'auto'}
      >
        <LetterViewer
          ref={letterViewerRef}
          isLoading={false}
          html={highlightedTemplateHtml ?? letterHtml}
          transformScale={transformScale}
        />
      </VStack>
    </Box>
  ) : (
    <Text fontSize="xl" color="grey.400">
      Select your template
    </Text>
  )
}
