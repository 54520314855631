import {
  Box,
  Button,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  VStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import Disclaimer from '~/assets/Disclaimer.svg'
import { routes } from '~constants/routes'
import { useUpdateTemplateMutation } from '~features/create/hooks/create.hooks'
import { GetTemplateDto } from '~shared/dtos/templates.dto'

interface SaveTemplateModalProps {
  isOpen: boolean
  onClose: () => void
  template: GetTemplateDto | undefined
  templateHtml: string
  templateName: string
}

export const SaveTemplateModal = ({
  isOpen,
  onClose,
  template,
  templateHtml,
  templateName,
}: SaveTemplateModalProps) => {
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useUpdateTemplateMutation()

  const onSubmit = async (): Promise<void> => {
    if (template) {
      await mutateAsync({
        name: templateName,
        templateId: template.id,
        html: templateHtml,
        version: template.version,
      })
    }
    navigate(`/${routes.admin.index}/${routes.admin.templates.index}/`)
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Image alignSelf="center" src={Disclaimer} borderTopRadius={'8px'} />
        <ModalHeader>Confirm changes?</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <VStack px="30px" spacing="2px" pb="15px">
          <Box px="0px" fontSize={'16px'}>
            Important: Please be aware that any changes to this template are
            permanent and will apply to all users once saved.
          </Box>
        </VStack>
        <Box px="30px">
          {isLoading && <Progress size="md" isIndeterminate />}
        </Box>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={onClose}
            border={0}
            mr={3}
            colorScheme="blue"
          >
            Cancel
          </Button>

          <Button
            type="submit"
            //eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SaveTemplateModal
