export const DEFAULT_TEMPLATES = [
  {
    name: 'Letter - Basic',
    html: `<style>
    @media only print {
    .container { margin: 0px; }
    .title { font-size: 20px; }
    .qr-code-container { flex-direction: row; }
    .qr-code { width: 25%; }
    }
    @media only screen and (max-width: 600px) {
    .container { margin: 22px; }
    .title { font-size: 16px; }
    .qr-code-container { flex-direction: column; }
    .qr-code { width: 100%; }
    }
    @media only screen and (min-width: 600px) {
    .container { margin: 48px; }
    .title { font-size: 20px; }
    .qr-code-container { flex-direction: row; }
    .qr-code { width: 25%; }
    }
    </style>
  <div class="container" style="color: #000000; font-family: arial, helvetica, sans-serif;">
  <p style="text-align: center; width: 100%;"><img style="margin: auto; display: block; height: 80px; width: auto;" src="__logo__" height="80"></p>
  <p>{{date_of_issue}}</p>
  <p>&nbsp;</p>
  <p>{{recipient_name}}</p>
  <p>{{address_1}}</p>
  <p>{{address_2}}</p>
  <p>&nbsp;</p>
  <p><strong><span class="title">Streamlining Government Communications: Digital Letters are here</span></strong></p>
  <p dir="ltr">&nbsp;</p>
  <p>Dear {{recipient_name}},</p>
  <p>&nbsp;</p>
  <p>We are excited to inform you about the upcoming launch of our new digital letter platform, an innovative step towards achieving a paperless government.</p>
  <p>&nbsp;</p>
  <p>The digital letter platform will streamline our communication processes, allowing for faster, and more secure correspondence.</p>
  <p>&nbsp;</p>
  <p>Thank you for your attention and support in helping us move towards a more sustainable and efficient future.</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>Sincerely,</p><p>[Your Name]</p><p>[Your Title/Department/Agency]</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <hr><br>
  <div style="line-height: 16px; font-size: 12px;">
  <p>__agencyName__</p>
  <p>__agencyAddress__</p>
  <p><a style="color: #0000ee;" href="__agencyDomain__" rel="noopener">__agencyDomain__</a></p>
  </div>
  <br>
  <div style="line-height: 16px; font-size: 12px;">
  <p>This is a computer generated letter. No signature is required.</p>
  <p>Go Green: Please consider the environment before printing this letter.</p>
  </div>
  </div>`,
    description: 'Designed for simple content like notices',
  },
  {
    name: 'Letter - Callout',
    html: `<style>
  @media only print {
  .container { margin: 0px; }
  .title { font-size: 20px; }
  .qr-code-container { flex-direction: row; }
  .qr-code { width: 25%; }
  }
  @media only screen and (max-width: 600px) {
  .container { margin: 22px; }
  .title { font-size: 16px; }
  .qr-code-container { flex-direction: column; }
  .qr-code { width: 100%; }
  }
  @media only screen and (min-width: 600px) {
  .container { margin: 48px; }
  .title { font-size: 20px; }
  .qr-code-container { flex-direction: row; }
  .qr-code { width: 25%; }
  }
  </style>
  <div class="container" style="color: #000000; font-family: arial, helvetica, sans-serif;">
  <p style="text-align: center; width: 100%;"><img style="margin: auto; display: block; height: 80px; width: auto;" src="__logo__" height="80"></p>
  <p>{{date_of_issue}}</p>
  <p>&nbsp;</p>
  <p>{{recipient_name}}</p>
  <p>{{address_1}}</p>
  <p>{{address_2}}</p>
  <p>&nbsp;</p>
  <p><strong><span class="title">Streamlining Government Communications: Digital Letters are here</span></strong></p>
  <p dir="ltr">&nbsp;</p>
  <p>Dear {{recipient_name}},</p>
  <p>&nbsp;</p>
  <p>We are excited to inform you about the upcoming launch of our new digital letter platform, an innovative step towards achieving a paperless government.</p>
  <p>&nbsp;</p>
  <p>The digital letter platform will streamline our communication processes, allowing for faster, and more secure correspondence.</p>
  <p>&nbsp;</p>
  <p>Thank you for your attention and support in helping us move towards a more sustainable and efficient future.</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>Sincerely,</p><p>[Your Name]</p><p>[Your Title/Department/Agency]</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <hr><br>
  <div class="qr-code-container" style="display: flex; align-items: center; background-color: #e7effc; padding: 24px; border-radius: 8px; gap: 16px;">
  <div style="flex: 1;">
  <p style="white-space: pre-wrap; style="font-size: 18px;"><strong>Anything to highlight?</strong></p>
  <p style="white-space: pre-wrap;">This section is ideal for highlighting important information or addressing payment requirements. If the image is unnecessary, it can be easily removed.</p></div>
  <img class="qr-code" src="https://file.go.gov.sg/letters-guide-qrcode.png"></div>
  <p>&nbsp;</p>
  <hr><br>
  <div style="line-height: 16px; font-size: 12px;">
  <p>__agencyName__</p>
  <p>__agencyAddress__</p>
  <p><a style="color: #0000ee;" href="__agencyDomain__" rel="noopener">__agencyDomain__</a></p>
  </div>
  <br>
  <div style="line-height: 16px; font-size: 12px;">
  <p>This is a computer generated letter. No signature is required.</p>
  <p>Go Green: Please consider the environment before printing this letter.</p>
  </div>
  </div>`,
    description:
      'Recommended if you have information that needs to be highlighted',
  },
  {
    name: 'Certificate',
    html: `<div style="position: relative;"><span style="color: rgb(0, 0, 0);"><img src="https://assets-stg.letters.gov.sg/images/qoo50g9kl3gkw730wdok.png" width="100%" height="100%"></span>
<div style="position: absolute; width: 100%; text-align: center; top: 12%;">
<p style="text-align: center; width: 100%;"><img style="margin: auto; display: block; height: 120px; width: auto;" src="__logo__" height="120"></p>
</div>
<div style="position: absolute; width: 100%; text-align: center; top: 25%;">
<p style="line-height: 1;"><span style="font-family: georgia, palatino; font-size: 56px; color: rgb(0, 0, 0);">Certificate of </span></p>
<p style="line-height: 1;"><span style="font-family: georgia, palatino; font-size: 56px; color: rgb(0, 0, 0);">Curiosity</span></p>
</div>
<div style="position: absolute; width: 100%; text-align: center; top: 40%;">
<p style="line-height: 1;">Presented to</p>
<table style="border-collapse: collapse; width: 100%; height: 128px;" border="1"><colgroup><col style="width: 10%;"><col style="width: 80%;"><col style="width: 10%;"></colgroup>
<tbody>
<tr style="height: 128px;">
<td style="height: 128px;">&nbsp;</td>
<td style="height: 128px; border-style: none; line-height: 1; text-align: center; vertical-align: middle;"><span style="color: rgb(0, 0, 0); font-size: 36px; font-family: georgia, palatino;">{{recipient_name}}</span></td>
<td style="height: 128px;">&nbsp;</td>
</tr>
</tbody>
</table>
<p>for the successful completion of sending out your e-letter <br>from LetterSG</p>
</div>
<div style="position: absolute; width: 100%; text-align: center; bottom: 24%;">
<table style="width: 100%; text-align: center; margin-top: 15px;"><colgroup><col style="width: 32.9624%;" width="10%"><col style="width: 34.2142%;" width="10%"><col style="width: 32.9624%;" width="10%"></colgroup>
<tbody>
<tr style="font-family: 'Brush Script MT', cursive; font-size: 24px;">
<td>&nbsp;</td>
<td style="border-bottom: 2px solid black; padding-bottom: 3px;"><span style="color: rgb(0, 0, 0); font-size: 36px;">Yung</span></td>
<td>&nbsp;</td>
</tr>
<tr>
<td>&nbsp;</td>
<td style="vertical-align: top; padding-top: 12px; font-family: Arial, Helvetica, sans-serif; font-size: 12px;">
<p>Your Name]</p>
<p>[Your Title/Department/Agency]</p>
</td>
</tr>
</tbody>
</table>
</div>
</div>`,
    description: 'Designed for achievements, milestones and certifications',
  },
]

export const DEFAULT_LETTER_TEMPLATE_STYLE = `<style>
    @media only print {
    .container { margin: 0px; }
    .title { font-size: 20px; }
    .qr-code-container { flex-direction: row; }
    .qr-code { width: 25%; }
    }
    @media only screen and (max-width: 600px) {
    .container { margin: 22px; }
    .title { font-size: 16px; }
    .qr-code-container { flex-direction: column; }
    .qr-code { width: 100%; }
    }
    @media only screen and (min-width: 600px) {
    .container { margin: 48px; }
    .title { font-size: 20px; }
    .qr-code-container { flex-direction: row; }
    .qr-code { width: 25%; }
    }
    </style>
  <div class="container" style="color: #000000; font-family: arial, helvetica, sans-serif;"><p></p></div>`
