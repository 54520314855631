import { isEmail, registerDecorator, ValidationOptions } from 'class-validator'
import { Minimatch } from 'minimatch'

const HEALTH_VALID_EMAIL_GLOB_EXPRESSION =
  '*@@(nuhs.edu|ihis.com|synapxe|alpshealthcare.com|1fss.com|ndcs.com|nni.com|nhcs.com|nccs.com|notu.com|scbb.com|snec.com|nsc.com|moht.com|mohh.com|nhgp.com|yishunhospital.com|ttsh.com|sgh.com|skh.com|kkh.com|imh.com|ktph.com|cgh.com|nhg.com|diagnostics.nhg.com|pharmacy.nhg.com|singhealth.com|singhealthch.com|wh.com|nhic.cris|actris.cris|precise.cris|stcc.cris|scri.cris|open.gov|cris|aic|ncid|pca).sg'

const EDU_VALID_EMAIL_GLOB_EXPRESSION =
  '*@@(moe.edu|nyp.edu|tp.edu|np.edu|ite.edu|sp.edu|rp.edu|sst.edu|nie.edu|schools.gov|moe.gov).sg'

const healthGlobValidator = new Minimatch(HEALTH_VALID_EMAIL_GLOB_EXPRESSION, {
  noext: false,
  noglobstar: true,
  nobrace: true,
  nonegate: true,
})

const eduGlobValidator = new Minimatch(EDU_VALID_EMAIL_GLOB_EXPRESSION, {
  noext: false,
  noglobstar: true,
  nobrace: true,
  nonegate: true,
})

export const isGovSgOrWhitelistedEmail = (value: unknown) => {
  return (
    typeof value === 'string' &&
    isEmail(value) &&
    (value.toString().endsWith('.gov.sg') ||
      healthGlobValidator.match(value.toString()) ||
      eduGlobValidator.match(value.toString()))
  )
}

export const IsGovSgOrWhitelistedEmail = (options?: ValidationOptions) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (object: Object, propertyName: string) => {
    registerDecorator({
      name: 'isGovSgOrWhitelistedEmail',
      target: object.constructor,
      propertyName,
      options,
      validator: {
        validate: (value: unknown) => isGovSgOrWhitelistedEmail(value),
      },
    })
  }
}
