import {
  Box,
  Button,
  HStack,
  IconButton,
  MenuButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Menu } from '@opengovsg/design-system-react'
import { useState } from 'react'
import {
  BiAddToQueue,
  BiArchive,
  BiArrowBack,
  BiChevronDown,
  BiDownload,
  BiEditAlt,
  BiUserPlus,
} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import { routes } from '~constants/routes'
import CreateTemplateModal, {
  SOURCE_TYPE,
} from '~features/editor/components/editor/CreateTemplateModal'
import { useGetTemplateHistory } from '~features/editor/hooks/templateHistory.hooks'
import { PreviewTemplateLibrary } from '~features/issue/components/previews/PreviewTemplateLibrary'
import { ManageCollaboratorAccessModal } from '~features/template-library/components/modals/ManageCollaboratorAccessModal'
import { GetTemplateDto } from '~shared/dtos/templates.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'
import { arrToCsv } from '~utils/csvUtils'
import { getLocaleTimeFromDate, parseDateV2 } from '~utils/dateUtils'
import { templateActionTypeToString } from '~utils/stringUtils'

import { useRestoreTemplate } from '../hooks/templateLibrary.hooks'
import { ConfirmArchiveModal } from './modals/ConfirmArchiveModal'

interface PreviewTemplateInOverlayProps {
  template: GetTemplateDto
  onClose: () => void
  isArchived: boolean
}

export const PreviewTemplateInOverlay = ({
  template,
  onClose,
  isArchived,
}: PreviewTemplateInOverlayProps) => {
  const navigate = useNavigate()
  const { templateHistory } = useGetTemplateHistory(template.id)
  const [isScrollAtTop, setIsScrollAtTop] = useState<boolean>(false)
  const changeTopbarColor = () => {
    if (window.scrollY === 0) {
      setIsScrollAtTop(true)
    } else {
      setIsScrollAtTop(false)
    }
  }
  window.addEventListener('scroll', changeTopbarColor)

  // ***** MODALS DISCLOSURE *****
  const {
    isOpen: isOpenCopyTemplate,
    onOpen: onOpenCopyTemplate,
    onClose: onCloseCopyTemplate,
  } = useDisclosure()

  const {
    isOpen: isOpenCollaboratorAccessModal,
    onOpen: onOpenCollaboratorAccessModal,
    onClose: onCloseCollaboratorAccessModal,
  } = useDisclosure()

  const {
    isOpen: isOpenConfirmArchiveModal,
    onOpen: onOpenConfirmArchiveModal,
    onClose: onCloseConfirmArchiveModal,
  } = useDisclosure()

  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )

  // ***** FUNCTION HANDLERS *****
  const handleIssueTemplate = () =>
    navigate(`/${routes.admin.index}/${routes.admin.templates.index}/issue`, {
      state: { template },
    })

  const handleDownloadTemplateCsv = () =>
    arrToCsv(`${template.name || ''} Template.csv`, template.fields)

  const handleEditTemplate = () =>
    navigate(
      `/${routes.admin.index}/${routes.admin.templates.index}/edit/${template.id}`,
      { state: { template } },
    )

  const { mutateAsync: handleRestoreTemplate, isLoading } = useRestoreTemplate({
    onSuccess: () => {
      onClose()
    },
  })

  return (
    <Box>
      <VStack spacing={3}>
        <HStack
          w={'100%'}
          py={4}
          px={10}
          display={'flex'}
          justify={'space-between'}
          position={'fixed'}
          zIndex={10}
          backgroundColor={isScrollAtTop ? 'none' : 'base.canvas.overlay'}
          pointerEvents="auto"
        >
          <HStack spacing={0} textColor={'standard.white'}>
            <Button onClick={onClose} variant={'unstyled'} border={'none'}>
              <BiArrowBack size={'1.3rem'} />
            </Button>
            <VStack align="start" spacing="3px">
              <Text
                textStyle={'h6'}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                maxWidth="60vw"
                fontSize={'18px'}
              >
                Preview of {template.name}
              </Text>
              {templateHistory?.length && (
                <Text
                  textStyle={'subhead-2'}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  maxWidth="60vw"
                  fontSize={'14px'}
                  textColor={'gray.300'}
                >
                  {templateActionTypeToString(templateHistory[0].actionType)}{' '}
                  {templateHistory[0].modifiedBy}
                  {' · '}
                  {parseDateV2(templateHistory[0].updatedAt.toString())}
                  {', '}
                  {getLocaleTimeFromDate(
                    templateHistory[0].updatedAt.toString(),
                  )}
                </Text>
              )}
            </VStack>
          </HStack>
          {/* TODO: consider moving this to a separate component */}
          {!isArchived && (
            <HStack spacing={0}>
              <Button
                alignSelf="start"
                onClick={handleIssueTemplate}
                borderEndRadius={'none'}
                position="relative"
                _after={{
                  content: '""',
                  position: 'absolute',
                  right: '-1px',
                  top: '10%',
                  bottom: '10%',
                  width: '1px',
                  backgroundColor: 'white',
                }}
              >
                Use this template
              </Button>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<BiChevronDown />}
                  transition="all 0.2s"
                  borderStartRadius={'none'}
                />
                <Menu.List borderRadius={3} my={1} py={1}>
                  <Menu.Item
                    icon={<BiDownload size="1.2rem" />}
                    onClick={handleDownloadTemplateCsv}
                  >
                    <Text>Download CSV</Text>
                  </Menu.Item>
                  {template.id !== sampleTemplateId && (
                    <Menu.Item
                      icon={<BiUserPlus size="1.2rem" />}
                      onClick={onOpenCollaboratorAccessModal}
                    >
                      <Text>Manage sharing access</Text>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    icon={<BiAddToQueue size="1.2rem" />}
                    onClick={onOpenCopyTemplate}
                  >
                    <Text>Duplicate template</Text>
                  </Menu.Item>
                  {template.id !== sampleTemplateId && (
                    <Menu.Item
                      icon={<BiEditAlt size="1.2rem" />}
                      onClick={handleEditTemplate}
                    >
                      <Text>Edit template</Text>
                    </Menu.Item>
                  )}
                  {template.id !== sampleTemplateId && (
                    <Menu.Item
                      icon={<BiArchive size="1.2rem" />}
                      onClick={onOpenConfirmArchiveModal}
                    >
                      <Text>Archive template</Text>
                    </Menu.Item>
                  )}
                </Menu.List>
              </Menu>
            </HStack>
          )}
          {isArchived && (
            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => handleRestoreTemplate({ templateId: template.id })}
              isLoading={isLoading}
            >
              Restore from archive
            </Button>
          )}
        </HStack>
        <Box pt={14} pointerEvents="auto">
          <PreviewTemplateLibrary
            templateHtml={template.html}
            letterScale={800}
          />
        </Box>
      </VStack>
      <CreateTemplateModal
        onClose={onCloseCopyTemplate}
        isOpen={isOpenCopyTemplate}
        originalTemplateName={template.name}
        source={SOURCE_TYPE.CLONE_PREVIEW}
        templateHtml={template.html}
        defaultTemplateName={`Copy of ${template.name}`}
      />
      <ManageCollaboratorAccessModal
        isOpen={isOpenCollaboratorAccessModal}
        onClose={onCloseCollaboratorAccessModal}
        templateId={template.id}
        templateName={template.name}
      />
      <ConfirmArchiveModal
        isOpen={isOpenConfirmArchiveModal}
        onClose={() => {
          onCloseConfirmArchiveModal()
          onClose()
        }}
        templateId={template.id}
      />
    </Box>
  )
}
