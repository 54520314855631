import { PLACE_HOLDER_PREFIX, PLACE_HOLDER_SUFFIX } from '../constants/letters'
import { LetterParamMap } from '../dtos/letters.dto'

export const replaceWithParams = (
  html: string,
  letterParamMap: LetterParamMap,
  prefix: string = PLACE_HOLDER_PREFIX,
  suffix: string = PLACE_HOLDER_SUFFIX,
): string => {
  for (const key in letterParamMap) {
    const value = letterParamMap[key]
    const placeHolder = `${prefix}${key}${suffix}`
    html = html.split(placeHolder).join(value)
  }
  return html
}
