import { Box, SimpleGrid, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import { routes } from '~constants/routes'
import { DEFAULT_TEMPLATES } from '~shared/constants/templates'
import { GetTemplateDto } from '~shared/dtos/templates.dto'
import {
  GrowthBookFeatures,
  GrowthBookFeaturesMap,
} from '~shared/types/feature-flag'

import { TemplatePreviewModal } from '../TemplatePreviewModal'
import { TemplateCard } from './TemplateCard'
interface TemplatesBodyProps {
  templates: GetTemplateDto[]
  showCreate?: boolean
  isArchived?: boolean
}

export const TemplatesBody = ({
  templates,
  showCreate = false,
  isArchived = false,
}: TemplatesBodyProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  const [initialIndex, setInitialIndex] = useState<number | null>(null)

  const defaultTemplates = useFeatureValue(
    GrowthBookFeatures.defaultTemplates as string,
    DEFAULT_TEMPLATES,
  ) as GrowthBookFeaturesMap[GrowthBookFeatures.defaultTemplates]

  return (
    <Box w={'100%'}>
      <SimpleGrid columns={[1, 2, 3, 4]} gap={13} w={'100%'}>
        {!isArchived && showCreate && (
          <Box w={'100%'}>
            <VStack spacing={3} align={'left'}>
              <Box
                onClick={() => {
                  defaultTemplates.length > 0
                    ? navigate(
                        `/${routes.admin.index}/${routes.admin.templates.index}/${routes.admin.templates.create}`,
                      )
                    : navigate(
                        `/${routes.admin.index}/${routes.admin.templates.index}/${routes.admin.templates.edit}`,
                      )
                }}
                aspectRatio={'0.71'}
                backgroundColor={'blue.100'}
                borderRadius={'4px'}
                border={'1px'}
                borderColor={'interaction.main.default'}
                _hover={{
                  cursor: 'pointer',
                  border: '2px solid',
                  borderColor: 'interaction.main.default',
                  shadow: 'md',
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BiPlus size={'2rem'} color="#445FCD" />
              </Box>
              <Text noOfLines={2} textStyle={'subhead-1'}>
                Create from our available templates
              </Text>
            </VStack>
          </Box>
        )}
        {templates.map((template, index) => (
          <Box key={index} w={'100%'}>
            <TemplateCard
              template={template}
              index={index}
              onClick={(index) => {
                onOpen()
                setInitialIndex(index)
              }}
            />
          </Box>
        ))}
      </SimpleGrid>
      {initialIndex !== null && (
        <TemplatePreviewModal
          isOpen={isOpen}
          onClose={() => {
            setInitialIndex(null)
            onClose()
          }}
          templates={templates}
          initialIndex={initialIndex}
          isArchived={isArchived}
        />
      )}
    </Box>
  )
}
