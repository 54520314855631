import { Box, VStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import { LetterViewer } from '~features/editor/components/LetterViewer'
import { calculateTransformScale } from '~features/public/hooks/useTransformScale'
import { LetterParamMap } from '~shared/dtos/letters.dto'
import { sanitizeHtml } from '~shared/util/html-sanitizer'
import { replaceWithParams } from '~shared/util/letters'
import {
  highlightVariablesInHTMLTemplate,
  onHtmlImagesLoaded,
} from '~utils/htmlUtils'

import { WatermarkOverlay } from '../WatermarkOverlay'
import { EmptyPreview } from './EmptyPreview'

interface PreviewTemplateProps {
  templateHtml?: string | null
  letterScale?: number
  isPlaceholdersHighlighted?: boolean
  letterParamMap?: LetterParamMap
}

export const PreviewTemplate = ({
  templateHtml,
  letterScale,
  isPlaceholdersHighlighted = false,
  letterParamMap,
}: PreviewTemplateProps) => {
  const transformScale = calculateTransformScale(letterScale ?? 750) // TODO: should be able to avoid hardcoding by reading from parent drawer component
  let letterHtml = sanitizeHtml(templateHtml ?? '')
  if (templateHtml && letterParamMap)
    letterHtml = replaceWithParams(templateHtml, letterParamMap)

  const [highlightedTemplateHtml, setHighlightedTemplateHtml] = useState<
    string | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (templateHtml && letterParamMap)
      letterHtml = replaceWithParams(templateHtml, letterParamMap)
    setHighlightedTemplateHtml(undefined)
    if (isPlaceholdersHighlighted && letterHtml) {
      const highlightedTemplateHtml =
        highlightVariablesInHTMLTemplate(letterHtml)

      setHighlightedTemplateHtml(highlightedTemplateHtml)
    }
  }, [letterHtml, isPlaceholdersHighlighted, letterParamMap])

  // introduce slight delay between rendering to allow letterHtml height to load correctly
  useEffect(() => {
    if (!letterHtml) return
    setIsLoading(true)
    const timeoutId = setTimeout(() => {
      setIsLoading(false)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [letterHtml])

  const [letterHeight, setLetterHeight] = useState<number | undefined>(0)

  const letterViewerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setLetterHeight(node.offsetHeight)
        onHtmlImagesLoaded(node, () => setLetterHeight(node.offsetHeight))
      }
    },
    [setLetterHeight],
  )

  return isLoading ? (
    <EmptyPreview isLoading />
  ) : letterHtml ? (
    <Box height="100%" width="100%" overflow="auto">
      <VStack align={'center'}>
        <Box
          position="relative"
          backgroundColor="white"
          margin="60px"
          borderRadius={'10px'}
          marginTop={'60px'}
          boxShadow={'0px 0px 10px 3px rgba(0, 0, 0, 0.1)'}
          py="20px"
          width={750}
          height={letterHeight ? letterHeight * transformScale + 40 : 'auto'}
        >
          <LetterViewer
            ref={letterViewerRef}
            isLoading={false}
            html={highlightedTemplateHtml ?? letterHtml}
            transformScale={transformScale}
          />
          <WatermarkOverlay
            boxHeight={letterHeight ? letterHeight * transformScale : null}
          />
        </Box>
      </VStack>
    </Box>
  ) : (
    <EmptyPreview
      title={'Letter Preview'}
      subtitle={
        'This is a preview of what recipients see depending on template you selected.'
      }
    />
  )
}
