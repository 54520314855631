import { Box, VStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import { LetterViewer } from '~features/editor/components/LetterViewer'
import { WatermarkOverlay } from '~features/issue/components/WatermarkOverlay'
import { calculateTransformScale } from '~features/public/hooks/useTransformScale'
import {
  highlightVariablesInHTMLTemplateEditor,
  onHtmlImagesLoaded,
} from '~utils/htmlUtils'

interface EditorLetterPreviewProps {
  templateContent: string
}
export const EditorLetterPreview = ({
  templateContent,
}: EditorLetterPreviewProps) => {
  const [highlightedTemplateHtml, setHighlightedTemplateHtml] = useState<
    string | undefined
  >()

  useEffect(() => {
    if (templateContent) {
      const highlightedTemplateHtml =
        highlightVariablesInHTMLTemplateEditor(templateContent)

      setHighlightedTemplateHtml(highlightedTemplateHtml)
    }
  }, [templateContent])

  const [letterHeight, setLetterHeight] = useState<number | undefined>(0)
  const letterViewerRef = useCallback(
    (node: HTMLDivElement) => {
      // TODO: investigate further on why image count is not correctly calculated even though
      // the node gets updates in the onHTMLImagesLoaded function.
      // add a slight delay before we call set letter height, to let html to load properly
      setTimeout(() => {
        if (node !== null) {
          onHtmlImagesLoaded(node, () => setLetterHeight(node.offsetHeight))
        }
      }, 10)
    },
    [setLetterHeight, templateContent],
  )

  const transformScale = calculateTransformScale(600)

  return (
    <Box height="100%" width="100%" overflow="auto">
      <VStack align={'center'}>
        <Box
          position="relative"
          backgroundColor="white"
          margin="60px"
          borderRadius={'10px'}
          marginTop={'60px'}
          boxShadow={'0px 0px 10px 3px rgba(0, 0, 0, 0.1)'}
          py="20px"
          width={600}
          height={letterHeight ? letterHeight * transformScale + 40 : 'auto'}
        >
          <LetterViewer
            ref={letterViewerRef}
            isLoading={false}
            html={highlightedTemplateHtml ?? templateContent}
            transformScale={transformScale}
          />
          <WatermarkOverlay
            boxHeight={letterHeight ? letterHeight * transformScale : null}
          />
        </Box>
      </VStack>
    </Box>
  )
}
